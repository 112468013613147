import jsPDF from 'jspdf';
import 'jspdf-autotable';
import logo from '../../../assets/nepovit-logo.png';

const Pdf = ({ complaintDetails }) => {
    const generatePDF = () => {
        const doc = new jsPDF();

        const boxX = 10;
        const boxY = 10;
        const boxWidth = 190;
        const boxHeight = 25;

        doc.setFillColor(173, 216, 230);
        doc.rect(boxX, boxY, boxWidth, boxHeight, 'F');
        doc.addImage(logo, 'JPEG', boxX + 10, boxY + 5, 20, 15);
        doc.setFontSize(18);
        doc.setTextColor(0, 0, 0);
        doc.text('Complaint Details', boxX + boxWidth - 20, boxY + 15, { align: 'right', baseline: 'middle' });

        doc.setFontSize(10);
        doc.setTextColor(40, 40, 40);
        doc.setLineWidth(1);
        doc.line(boxX, boxY + boxHeight, boxX + boxWidth, boxY + boxHeight);

        const tableData = [];

        const addTableRow = (label, value) => {
            tableData.push([
                { content: label, styles: { halign: 'left', fontStyle: 'bold', textColor: [0, 0, 0] } },
                { content: value || 'N/A', styles: { halign: 'left', textColor: [0, 0, 0] } }
            ]);
        };

        addTableRow('Complaint No:', complaintDetails.complaint_no);
        addTableRow('Grievance Type:', complaintDetails.type);
        addTableRow('Complaint Date:', new Date(complaintDetails.created_at).toLocaleDateString('en-GB', { day: '2-digit', month: '2-digit', year: 'numeric' }));
        addTableRow('Complaint Status:', complaintDetails.status);

        if (complaintDetails.type === 'billing') {
            addTableRow('Customer Name:', complaintDetails.customer_name);
            addTableRow('Complaint Remarks:', complaintDetails.complaint_remarks);
            addTableRow('Invoice Date:', complaintDetails.invoice_date.split('-').reverse().join('/'));
            addTableRow('Invoice No:', complaintDetails.invoice_no);
            addTableRow('Complaint Amount:', complaintDetails.invoice_amount);
        } else if (complaintDetails.type === 'product') {
            addTableRow('Complaint Type:', complaintDetails.complaint_type);
            addTableRow('Distributor Name:', complaintDetails.distributor_name);
            addTableRow('Distributor Phone No:', complaintDetails.distributor_phone_no);
            addTableRow('Dealer Name:', complaintDetails.dealer_name);
            addTableRow('Dealer Phone No:', complaintDetails.dealer_phone_no);
            addTableRow('Customer Name:', complaintDetails.customer_name);
            addTableRow('Customer Address:', complaintDetails.customer_address);
            addTableRow('Customer Phone No:', complaintDetails.customer_phone_no);
            addTableRow('Product Name:', complaintDetails.product_name);
            addTableRow('Product Size:', complaintDetails.product_size);
            addTableRow('Product Quantity (in box):', complaintDetails.complaint_qty_box);
            addTableRow('Invoice Date:', complaintDetails.invoice_date.split('-').reverse().join('/'));
            addTableRow('Invoice No:', complaintDetails.invoice_no);
            addTableRow('Complaint Amount:', complaintDetails.invoice_amount);
        } else if (complaintDetails.type === 'dispatch') {
            addTableRow('Distributor Name:', complaintDetails.distributor_name);
            addTableRow('Dealer Name:', complaintDetails.dealer_name);
            addTableRow('Customer Name:', complaintDetails.customer_name);
            addTableRow('Product Name:', complaintDetails.product_name);
            addTableRow('Product Size:', complaintDetails.product_size);
            addTableRow('Product Quantity (in box):', complaintDetails.complaint_qty_box);
            addTableRow('Complaint Remarks:', complaintDetails.complaint_remarks);
            addTableRow('Invoice Date:', complaintDetails.invoice_date.split('-').reverse().join('/'));
            addTableRow('Invoice No:', complaintDetails.invoice_no);
            addTableRow('Complaint Amount:', complaintDetails.invoice_amount);
        }

        doc.autoTable({
            startY: boxY + boxHeight + 10,
            margin: { horizontal: 10 },
            head: [['Field', 'Value']],
            body: tableData,
            theme: 'grid',
            headStyles: {
                fillColor: [0, 123, 255],
                textColor: [255, 255, 255],
                fontStyle: 'bold',
                fontSize: 10
            },
            alternateRowStyles: {
                fillColor: [240, 240, 240]
            },
            styles: {
                fontSize: 10,
                cellPadding: 5,
                halign: 'left',
                valign: 'middle',
                lineColor: [200, 200, 200],
                lineWidth: 0.5
            },
            tableWidth: 'auto',
            columnWidth: [70, 120]
        });

        doc.save(`complaint-${complaintDetails.complaint_no}.pdf`);

    };

    return (
        <button onClick={generatePDF} className="bg-blue-600 text-white py-2 px-6 rounded-lg shadow-lg hover:bg-blue-700 transform hover:scale-105 transition-transform duration-200">
            PDF
        </button>
    );
};

export default Pdf;
