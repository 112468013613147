import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiUrl } from "../apiConfig";

const getTokenFromLocalStorage = () => {
    return localStorage.getItem("token");
};

// Fetch complaints for RSM
export const getresmcomplaint = createAsyncThunk(
    "rsm/getresmcomplaint",
    async (_, { rejectWithValue }) => {
        try {
            const token = getTokenFromLocalStorage();
            const response = await fetch(`${apiUrl}rm`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                    Accept: "*/*",
                },
            });

            if (!response.ok) {
                const errorData = await response.json();
                return rejectWithValue(errorData);
            }

            const data = await response.json();
            return data;
        } catch (error) {
            return rejectWithValue({ message: 'An error occurred during the request.' });
        }
    }
);

// Add complaint for RSM
export const addrsmregisterComplaint = createAsyncThunk(
    'rsm/addrsmregisterComplaint',
    async (complaintData, { rejectWithValue }) => {
        try {
            const token = getTokenFromLocalStorage();
            const formData = new FormData();
            const complaint = complaintData;

            console.log("complaint...>>>>>>>>>" + complaint)
            console.log("complaint...>>>>>>>>><<<<<<<<<<<<<<" + complaint.complaint)

            formData.append('type', complaintData.grievanceType);
            formData.append('distributor_name', complaint.complaint.distributor_name);
            formData.append('distributor_phone_no', complaint.complaint.distributor_phone_no);
            formData.append('dealer_name', complaint.complaint.dealer_name);
            formData.append('dealer_phone_no', complaint.complaint.dealer_phone_no);
            formData.append('customer_name', complaint.complaint.customer_name);
            formData.append('customer_phone_no', complaint.complaint.customer_phone_no);
            formData.append('customer_address', complaint.complaint.customer_address);
            formData.append('product_name', complaint.complaint.product_name);
            formData.append('product_size', complaint.complaint.product_size);
            formData.append('complaint_qty_box', complaint.complaint.complaint_qty_box);
            formData.append('complaint_type', complaint.complaint.complaint_type);
            formData.append('complaint_remarks', complaint.complaint.complaint_remarks);
            formData.append('invoice_no', complaint.complaint.invoice_no);
            formData.append('invoice_date', complaint.complaint.invoice_date);
            formData.append('invoice_amount', complaint.complaint.invoice_amount);
            formData.append('vehicle_number', complaint.complaint.vehicle_number);
            formData.append('batch_no', complaint.complaint.batch_no);
            formData.append('tag', complaint.complaint.tag);

            if (complaintData.images && Array.isArray(complaintData.images)) {
                complaintData.images.forEach((image, index) => {
                    formData.append('images[]', image);
                });
            }

            const response = await fetch(`${apiUrl}rm/add`, {
                method: 'POST',
                headers: {
                    Authorization: `Bearer ${token}`,
                    Accept: "*/*",
                },
                body: formData,
            });

            if (!response.ok) {
                const errorData = await response.json();
                return rejectWithValue(errorData);
            }

            return await response.json();
        } catch (error) {
            return rejectWithValue({ message: 'An error occurred during the request.' });
        }
    }
);
//3
export const updateresmcomplaint = createAsyncThunk(
    "updateresmcomplaint",
    async (datas, { rejectWithValue }) => {
        try {
            const token = getTokenFromLocalStorage();
            const response = await fetch(`${apiUrl}rm/update`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                    Accept: "*/*",
                },
                body: JSON.stringify(datas),
            });

            if (!response.ok) {
                const errorData = await response.json();
                return rejectWithValue(errorData);
            }

            const data = await response.json();
            return data;
        } catch (error) {
            return rejectWithValue({ message: 'An error occurred during the request.' });
        }
    }
);

const rsmcomplaintSlice = createSlice({
    name: 'rsmcomplaint',
    initialState: {
        rsmstatus: 'idle',
        getStatus: 'idle',
        updateStatus: 'idle',
        rsmdata: [],
        error: null,
        status: 'idle',
        complaint: null
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getresmcomplaint.pending, (state) => {
                state.getStatus = 'pending';
            })
            .addCase(getresmcomplaint.fulfilled, (state, action) => {
                state.getStatus = 'succeeded';
                state.rsmdata = action.payload.data;
            })
            .addCase(getresmcomplaint.rejected, (state, action) => {
                state.getStatus = 'failed';
                state.error = action.payload;
            })
            .addCase(addrsmregisterComplaint.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(addrsmregisterComplaint.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.complaint = action.payload;
            })
            .addCase(addrsmregisterComplaint.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload;
            })
            //3
            .addCase(updateresmcomplaint.pending, (state) => {
                state.updateStatus = 'loading';
            })
            .addCase(updateresmcomplaint.fulfilled, (state, action) => {
                state.updateStatus = 'succeeded';
                state.complaint = action.payload;
            })
            .addCase(updateresmcomplaint.rejected, (state, action) => {
                state.updateStatus = 'failed';
                state.error = action.payload;
            });
    }
});

export default rsmcomplaintSlice.reducer;
