// src/app/store.js
import { configureStore } from '@reduxjs/toolkit';
import complaintReducer from '../slices/user/complaintSlice';
import userReducer from '../slices/admin/registerUserSlice';
import authReducer from '../slices/authSlice/authSlice';
import complaintListReducer from '../slices/admin/complaintListSlice';
import salesComplaintReducer from '../slices/SalesSlice/salesComplaintSlice';
import accountcomplaintReducer from '../slices/Account/accountcomplaintslice';
import tsmcomplaintReducer from '../slices/Tsm/tsmcomplaintslice';
import asmcomplaintReducer from '../slices/Asm/asmcomplaintslice';
import sheadcomplaintReducer from '../slices/Shead/sheadcomplaintslice';
import dispatchcomplaintReducer from '../slices/Dispatch/dispatchcomplaintslice';
import productioncomplaintReducer from '../slices/Production/productionComplaintSlice';
import rsmcomplaintReducer from '../slices/Rsm/rsmcomplaint';


export const store = configureStore({
    reducer: {
        complaints: complaintReducer,
        users: userReducer,     // admin register user 
        auth: authReducer,     //for the different user login
        complaintList: complaintListReducer, //   complainit list for admin
        salesComplaint: salesComplaintReducer,
        accountComplaint: accountcomplaintReducer,
        sheadComplaint: sheadcomplaintReducer,
        dispatchComplaint: dispatchcomplaintReducer,
        tsmComplaint: tsmcomplaintReducer,
        asmComplaint: asmcomplaintReducer,
        productionComplaint: productioncomplaintReducer,
        rsmcomplaint: rsmcomplaintReducer,
    },
});
