
import { useSelector } from 'react-redux';
import { registerComplaint } from '../../redux/slices/user/complaintSlice';
import Navbar from '../home/Navbar';
import Form from '../utils/ComplaintForm/Form'



const RegisterComplaint = () => {

    const complaintStatus = useSelector(state => state.complaints.status);
    const complaintError = useSelector(state => state.complaints.error);





    return (
        <>
            <Navbar />
            <Form
                registerComplaint={registerComplaint}
                complaintStatus={complaintStatus}
                complaintError={complaintError}
                backToComplaint={'complaint'}
            />

        </>
    );
};

export default RegisterComplaint;