import React from 'react';

const Filters = ({ grievanceFilter, statusFilter, handleGrievanceChange, handleStatusChange }) => {
    const grievanceOptions = [
        { label: 'All', value: '' },
        { label: 'Billing', value: 'billing' },
        { label: 'Dispatch', value: 'dispatch' },
        { label: 'Product', value: 'product' },
    ];

    const statusOptions = [
        { label: 'All', value: '' },
        { label: 'Open', value: 'open' },
        { label: 'Close', value: 'close' },
        { label: 'Reject', value: 'reject' },
    ];

    return (
        <div className="flex gap-4">
            <div>
                <label className="block text-sm font-bold mb-2">Grievance</label>
                <select
                    value={grievanceFilter}
                    onChange={handleGrievanceChange}
                    className="border border-gray-300 rounded-lg p-2 w-full"
                >
                    {grievanceOptions.map((option) => (
                        <option key={option.value} value={option.value}>
                            {option.label}
                        </option>
                    ))}
                </select>
            </div>
            <div>
                <label className="block text-sm font-bold mb-2">Status</label>
                <select
                    value={statusFilter}
                    onChange={handleStatusChange}
                    className="border border-gray-300 rounded-lg p-2 w-full"
                >
                    {statusOptions.map((option) => (
                        <option key={option.value} value={option.value}>
                            {option.label}
                        </option>
                    ))}
                </select>
            </div>
        </div>
    );
};

export default Filters;