import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { apiUrl } from '../apiConfig';
const getTokenFromLocalStorage = () => {
    return localStorage.getItem("token");
};// Define the async thunk
export const registerComplaint = createAsyncThunk(
    'complaints/registerComplaint',
    async (complaintData, { rejectWithValue }) => {
        try {
            const token = getTokenFromLocalStorage();
            // Initialize FormData
            const formData = new FormData();

            // Append non-file fields to FormData
            const complaint = complaintData.complaint;
            formData.append('type', complaintData.grievanceType);
            formData.append('distributor_name', complaint.distributor_name);
            formData.append('distributor_phone_no', complaint.distributor_phone_no);
            formData.append('dealer_name', complaint.dealer_name);
            formData.append('dealer_phone_no', complaint.dealer_phone_no);
            formData.append('customer_name', complaint.customer_name);
            formData.append('customer_phone_no', complaint.customer_phone_no);
            formData.append('customer_address', complaint.customer_address);
            formData.append('product_name', complaint.product_name);
            formData.append('product_size', complaint.product_size);
            formData.append('complaint_qty_box', complaint.complaint_qty_box);
            formData.append('complaint_type', complaint.complaint_type);
            formData.append('complaint_remarks', complaint.complaint_remarks);
            formData.append('invoice_no', complaint.invoice_no);
            formData.append('invoice_date', complaint.invoice_date);
            formData.append('invoice_amount', complaint.invoice_amount);
            formData.append('batch_no', complaint.batch_no);
            formData.append('vehicle_number', complaint.vehicle_number);
            formData.append('tag', complaint.tag);

            if (complaintData.images && Array.isArray(complaintData.images)) {
                complaintData.images.forEach((image, index) => {
                    formData.append('images[]', image);
                });
            }

            // Make the API request
            const response = await fetch(`${apiUrl}complaint/register`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    // Do not set 'Content-Type' header here
                },
                body: formData,
            });

            // Handle non-200 responses
            if (!response.ok) {
                const errorData = await response.json();
                return rejectWithValue(errorData);
            }

            // Parse and return the JSON response
            return await response.json();
        } catch (error) {
            // Handle any unexpected errors
            return rejectWithValue({ message: 'An error occurred during the request.' });
        }
    }
);




const complaintSlice = createSlice({
    name: 'complaints',
    initialState: {
        status: 'idle', // default status
        complaint: {},
        error: {},
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(registerComplaint.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(registerComplaint.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.complaint = action.payload;
            })
            .addCase(registerComplaint.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload;
            });
    },
});

export default complaintSlice.reducer;