import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import SheadNavbar from './SheadNavbar';
import { useDispatch, useSelector } from 'react-redux';
import { getSheadComplaint } from '../../redux/slices/Shead/sheadcomplaintslice';

const SheadHome = () => {
    const dispatch = useDispatch();
    const { sheadData } = useSelector(state => state.sheadComplaint);

    const [openCount, setOpenCount] = useState(0);
    const [closedCount, setClosedCount] = useState(0);
    const [rejectedCount, setRejectedCount] = useState(0);

    useEffect(() => {
        dispatch(getSheadComplaint());
    }, [dispatch]);

    useEffect(() => {
        if (sheadData) {
            const open = sheadData.filter(complaint => complaint.status === 'open').length;
            const closed = sheadData.filter(complaint => complaint.status === 'close').length;
            const rejected = sheadData.filter(complaint => complaint.status === 'reject').length;

            setOpenCount(open);
            setClosedCount(closed);
            setRejectedCount(rejected);
        }
    }, [sheadData]);

    return (
        <>
            <SheadNavbar />
            <div className="bg-gray-100 min-h-screen flex justify-center items-center p-6">
                <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8 max-w-md lg:translate-x-20">
                    <Link to='/shead-complaint'>
                        <div className=" h-48 lg:w-72 lg:h-48 bg-gradient-to-r w-64 from-blue-300 to-blue-500 border border-gray-200 rounded-xl shadow-lg p-6 hover:shadow-2xl hover:scale-105 transition-transform duration-300">
                            <h2 className="text-lg font-bold text-white mb-4 text-center">Complaints</h2>
                            <div className="flex flex-col space-y-3">
                                <div className="flex justify-between items-center">
                                    <p className="text-sm font-medium text-white">Open</p>
                                    <p className="text-xl font-extrabold text-white">{openCount}</p>
                                </div>
                                <div className="flex justify-between items-center">
                                    <p className="text-sm font-medium text-white">Closed</p>
                                    <p className="text-xl font-extrabold text-white">{closedCount}</p>
                                </div>
                                <div className="flex justify-between items-center">
                                    <p className="text-sm font-medium text-white">Rejected</p>
                                    <p className="text-xl font-extrabold text-white">{rejectedCount}</p>
                                </div>
                            </div>
                        </div>
                    </Link>

                </div>
            </div>
        </>
    );
}

export default SheadHome;
