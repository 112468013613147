import { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link, NavLink, useLocation, useNavigate } from 'react-router-dom';
import { logout } from '../../redux/slices/authSlice/authSlice';
import 'font-awesome/css/font-awesome.min.css';
import logo from '../../assets/nepovit-logo.png';


const ProductionNavbar = () => {
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [toggle, setToggle] = useState(false);
    const { user } = useSelector((state) => state.auth);
    const dispatch = useDispatch();
    const navbarRef = useRef(null);
    const navigate = useNavigate();
    const location = useLocation();


    const name = localStorage.getItem('name');

    const handleDropdownToggle = () => {
        setToggle(false);
        setDropdownOpen(!dropdownOpen);
    };

    const handleToggle = () => {
        setDropdownOpen(false);
        setToggle(!toggle);
    };

    useEffect(() => {
        if (location.pathname === '/production') {
            setToggle(true);

        }
    }, [location])

    const handleLogout = () => {
        dispatch(logout());
        setDropdownOpen(false); // Close the dropdown on logout
    };

    useEffect(() => {
        const handleOutsideClick = (event) => {
            if (navbarRef.current && !navbarRef.current.contains(event.target)) {
                setToggle(false);
                setDropdownOpen(false);
            }
        };
        document.addEventListener('click', handleOutsideClick);
        return () => {
            document.removeEventListener('click', handleOutsideClick);
        };
    }, []);

    return (
        <nav ref={navbarRef} className="fixed top-0 left-0 right-0 lg:h-[5rem] h-16 bg-[rgb(14,92,167)] text-white p-7 flex justify-between items-center shadow-lg z-50 lg:p-10">            {/* Sidebar Toggle Button */}
            {/* Sidebar Toggle Button */}
            <button
                className="text-white z-30 hover:text-red-500 hover:scale-125 focus:outline-none"
                onClick={handleToggle}
            >
                <svg
                    className="h-6 w-6"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M4 6h16M4 10h16M4 14h16M4 18h16"
                    ></path>
                </svg>
            </button>

            {/* Sidebar */}
            {toggle && (
                <div
                    className="fixed inset-0 top-0 left-0 pt-16 w-64  bg-[rgb(14,92,167)] text-white py-4 transition-transform duration-300 transform translate-x-0 lg:translate-x-0 lg:fixed lg:top-0 lg:left-0 lg:h-screen lg:w-1/5 bg-opacity-70 backdrop-blur-lg">

                    <div className='flex flex-col items-center space-y-4 p-4'>
                        <NavLink
                            to="/production"
                            className={({ isActive }) =>
                                `text-center bg-[#28205E] text-white py-2 rounded-lg shadow-md transition-colors duration-300 w-full ${isActive ? 'bg-red-500' : 'hover:bg-[#1f1a4d]'}`
                            }
                        >
                            Dashboard
                        </NavLink>

                        <NavLink
                            to="/production-complaint"
                            className={({ isActive }) =>
                                `text-center bg-[#28205E] text-white py-2 rounded-lg shadow-md transition-colors duration-300 w-full ${isActive ? 'bg-red-500' : 'hover:bg-[#1f1a4d]'}`
                            }
                        >
                            Complaint
                        </NavLink>
                    </div>
                    <div className="fixed bottom-0 left-0 right-0 flex flex-col items-center p-2 space-y-2 text-sm bg-gradient-to-r from-[#28205E] via-[#4c3c92] to-[#6b5cb8] rounded-lg border border-gray-500 shadow-lg">
                        <p className="text-gray-300"> Made with <i className="fa fa-heart text-red-500" aria-hidden="true"></i> by </p>
                        <p className="font-bold text-white tracking-wider"> RUMINATE IT SOLUTION </p>
                    </div>

                </div>
            )}

            <div className="flex items-center lg:h-[80px] lg:w-[130px]  lg:ml-40 h-[65px] w-[110px] transition duration-300 ease-in-out">
                <Link to='/production'>
                    <img src={logo} className=" object-contain hover:scale-110 transition duration-300 ease-in-out" />
                </Link>
            </div>

            {/* Profile Dropdown */}
            <div className="relative">
                <button onClick={handleDropdownToggle} className="text-white text-3xl">
                    <span className='flex gap-2' >
                        <h2 className="hidden lg:block text-lg font-semibold">Welcome {name}!</h2>
                        <i className="fa fa-user-circle-o hover:text-red-500" />
                    </span>
                </button>
                {dropdownOpen && (
                    <div className="absolute right-0 mt-8 w-48 bg-blue-600 text-white rounded-lg shadow-lg z-50 bg-opacity-70 backdrop-blur-lg">
                        <h2 className="block sm:hidden text-sm lg:hidden  m-3 ml-7 mb-0 font-semibold">Welcome {name}!</h2>

                        <div className="px-7 py-2">
                            <button
                                onClick={() => navigate('/change')}
                                className="w-full bg-gray-500 text-white text-sm py-2 rounded-lg shadow-md transition-colors duration-300 hover:bg-gray-400"
                            >
                                Change Password
                            </button>
                        </div>
                        <div className="px-7 py-2">
                            <button
                                onClick={handleLogout}
                                className="w-full bg-red-600 text-white text-sm py-2 rounded-lg shadow-md transition-colors duration-300 hover:bg-red-500"
                            >
                                Logout
                            </button>
                        </div>
                    </div>
                )}
            </div>
        </nav>
    );
};

export default ProductionNavbar;
