import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { loginUser } from '../../redux/slices/authSlice/authSlice';
import { Link, useNavigate } from 'react-router-dom';
import Loader from '../loader/Loader';
import logo from '../../assets/nepovit-logo.png';

const UserLogin = () => {
    const [formData, setFormData] = useState({ email: '', password: '' });
    const [errors, setErrors] = useState({});

    const { token, roleId, loading, error } = useSelector((state) => state.auth);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        if (token) {
            let targetPath = '/';

            switch (roleId) {
                case '1': targetPath = '/admin'; break;
                case '2': targetPath = '/account'; break;
                case '3': targetPath = '/production'; break;
                case '4': targetPath = '/shead'; break;
                case '5': targetPath = '/dispatch'; break;
                case '6': targetPath = '/rsm'; break;
                case '7': targetPath = '/sales'; break;
                case '8': targetPath = '/tsm'; break;
                case '9': targetPath = '/asm'; break;
                default: targetPath = '/'; break;
            }

            navigate(targetPath);
        }
    }, [token, roleId, navigate]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const validate = () => {
        const newErrors = {};
        if (!formData.email) newErrors.email = 'Email is required';
        if (!formData.password) newErrors.password = 'Password is required';
        return newErrors;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const validationErrors = validate();

        if (Object.keys(validationErrors).length === 0) {
            dispatch(loginUser(formData));
        } else {
            setErrors(validationErrors);
        }
    };

    return (
        <div className="h-screen bg-[rgb(14,92,167)] flex justify-center items-center ">
            {loading === true && (
                <Loader />
            )}
            <div className="lg:w-[35vw] sm:w-full p-4 md:p-6 lg:p-8 bg-[#292161] rounded-lg shadow-md mx-4 md:mx-6 ">
                <div className="p-4 md:p-6 lg:p-8 text-center">
                    <div className="text-3xl md:text-4xl font-bold text-white mb-4 flex justify-center">
                        <div className="flex items-center lg:h-[100px] lg:w-[150px] md:h-[80px] md:w-[120px] h-[60px] w-[100px] transition duration-300 ease-in-out">
                            <img
                                src={logo}
                                className="object-contain  border border-white  hover:scale-110 transition duration-300 ease-in-out"
                                alt="Logo"
                            />
                        </div>
                    </div>
                    <form onSubmit={handleSubmit} className='space-y-7'>
                        <div className="flex flex-col">
                            {/* <label htmlFor="email" className="text-sm font-medium text-white">Email</label> */}
                            <input
                                type="email"
                                name="email"
                                id="email"
                                value={formData.email}
                                onChange={handleChange}
                                placeholder="Enter your Email Id"
                                className={`w-full p-2 md:p-3 lg:p-4 pl-3 border rounded focus:border-red-500 focus:ring-red-500 focus:text-red-500 ${errors.email ? 'border-red-500' : 'border-white'}`}
                            />
                            {errors.email && <p className="text-red-500 text-sm mt-1">{errors.email}</p>}
                        </div>
                        <div className="flex flex-col">
                            {/* <label htmlFor="password" className="text-sm font-medium text-white">Password</label> */}
                            <input
                                type="password"
                                name="password"
                                id="password"
                                value={formData.password}
                                onChange={handleChange}
                                placeholder="Password"
                                className={`w-full p-2 md:p-3 lg:p-4 pl-3 border rounded focus:border-red-500 focus:ring-red-500 focus:text-red-500 ${errors.password ? 'border-red-500' : 'border-white'}`}
                            />
                            {errors.password && <p className="text-red-500 text-sm mt-1">{errors.password}</p>}
                        </div>
                        <div className="flex mt-8 justify-between items-center mb-4 gap-8">
                            <div className="flex items-center">
                                <input
                                    type="checkbox"
                                    id="remember"
                                    name="remember"
                                    className="h-4 w-4 text-red-500 focus:ring-red-500 border-gray-300 rounded"
                                />
                                <label htmlFor="remember" className="ml-2 text-sm text-white">Remember me</label>
                            </div>
                            <Link to="/forgot"
                                className="text-sm text-red-500 hover:text-red-700">
                                Forgot password?
                            </Link>
                        </div>
                        <button
                            type="submit"
                            className="w-full bg-red-500 text-white py-2 md:py-3 lg:py-4 px-4 md:px-6 lg:px-8 rounded shadow-md transform transition duration-300 hover:bg-red-700 disabled:bg-gray-500"
                            disabled={loading}
                        >
                            {loading ? 'Logging in...' : 'Login'}
                        </button>
                        {error && <p className="text-red-500 text-sm mt-4">{error}</p>}
                    </form>
                </div>
            </div>
        </div>
    );
}

export default UserLogin;
