import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { apiUrl } from '../apiConfig';

// Thunk for user login
export const loginUser = createAsyncThunk('auth/loginUser', async (credentials, thunkAPI) => {
    try {
        const response = await fetch(`${apiUrl}sign-in`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(credentials),
        });

        const data = await response.json();

        if (!response.ok) {
            return thunkAPI.rejectWithValue(data.msg || 'Login failed');
        }

        const { token, role_id, name } = data.data;
        localStorage.setItem('token', token);
        localStorage.setItem('roleId', role_id);
        localStorage.setItem('name', name);
        return { token, roleId: role_id, name };
    } catch (error) {
        return thunkAPI.rejectWithValue('An error occurred');
    }
});

// Thunk for forgot password
export const forgotPassword = createAsyncThunk('auth/forgotPassword', async (email, thunkAPI) => {
    try {
        const response = await fetch(`${apiUrl}password/email`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ email }),
        });

        const data = await response.json();

        if (!response.ok) {
            return thunkAPI.rejectWithValue(data.msg || 'Password reset failed');
        }

        return data.msg || 'Password reset email sent successfully';
    } catch (error) {
        return thunkAPI.rejectWithValue('An error occurred');
    }
});

// Thunk for reset password
export const resetPassword = createAsyncThunk(
    'auth/resetPassword',
    async ({ new_password, new_password_confirmation, token, email }, thunkAPI) => {
        try {
            const response = await fetch(`${apiUrl}password/reset`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    new_password,
                    new_password_confirmation,
                    token,
                    email
                }),
            });

            const data = await response.json();

            if (!response.ok) {
                return thunkAPI.rejectWithValue(data.msg || 'Password reset failed');
            }

            return data.msg || 'Password has been reset successfully';
        } catch (error) {
            return thunkAPI.rejectWithValue('An error occurred while resetting the password');
        }
    }
);

// Thunk for change password
export const changePassword = createAsyncThunk('auth/changePassword', async ({ current_password, new_password, new_password_confirmation }, thunkAPI) => {
    const token = localStorage.getItem('token');

    if (!token) {
        return thunkAPI.rejectWithValue('No authentication token found');
    }

    try {
        const response = await fetch(`${apiUrl}auth/change-password`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            },
            body: JSON.stringify({ current_password, new_password, new_password_confirmation }),
        });

        const data = await response.json();

        if (!response.ok) {
            return thunkAPI.rejectWithValue(data.errors || 'Password change failed');
        }

        return data.msg || 'Password changed successfully';
    } catch (error) {
        return thunkAPI.rejectWithValue('An error occurred');
    }
});

// Thunk for logout
export const logout = () => (dispatch) => {
    localStorage.removeItem('token');
    localStorage.removeItem('roleId');
    localStorage.removeItem('name');
    dispatch(authSlice.actions.logout());
};

const authSlice = createSlice({
    name: 'auth',
    initialState: {
        token: localStorage.getItem('token') || null,
        roleId: localStorage.getItem('roleId') || null,
        name: localStorage.getItem('name') || null,
        loading: false,
        error: null,
        resetMessage: null,
        changePasswordMessage: null,
    },
    reducers: {
        logout: (state) => {
            state.token = null;
            state.roleId = null;
            state.name = null;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(loginUser.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(loginUser.fulfilled, (state, action) => {
                state.token = action.payload.token;
                state.roleId = action.payload.roleId;
                state.name = action.payload.name;
                state.loading = false;
            })
            .addCase(loginUser.rejected, (state, action) => {
                state.error = action.payload;
                state.loading = false;
            })
            .addCase(forgotPassword.pending, (state) => {
                state.loading = true;
                state.error = null;
                state.resetMessage = null;
            })
            .addCase(forgotPassword.fulfilled, (state, action) => {
                state.resetMessage = action.payload;
                state.loading = false;
            })
            .addCase(forgotPassword.rejected, (state, action) => {
                state.error = action.payload;
                state.loading = false;
            })
            .addCase(resetPassword.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(resetPassword.fulfilled, (state, action) => {
                state.resetMessage = action.payload;
                state.loading = false;
            })
            .addCase(resetPassword.rejected, (state, action) => {
                state.error = action.payload;
                state.loading = false;
            })

            .addCase(changePassword.pending, (state) => {
                state.loading = true;
                state.error = null;
                state.changePasswordMessage = null;
            })
            .addCase(changePassword.fulfilled, (state, action) => {
                state.changePasswordMessage = action.payload;
                state.loading = false;
            })
            .addCase(changePassword.rejected, (state, action) => {
                state.error = action.payload;
                state.loading = false;
            });
    },
});

export default authSlice.reducer;
