import React from 'react';
import {
  createBrowserRouter,
  RouterProvider,
  Route,
  createRoutesFromElements,
} from 'react-router-dom';

import UserLogin from './pages/account/UserLogin';
import ForgotPassword from './pages/account/ForgotPassword';
import ChangePassword from './pages/account/ChangePassword';

import Home from './component/home/Home';
import Navbar from './component/home/Navbar';
import Complaint from './component/complaints/Complaint';
import RegisterComplaint from './component/complaints/RegisterComplaint';
import ComplaintStructure from './component/complaints/ComplaintStructure';

import UserRegister from './pages/Admin/UserRegister/UserRegister';
import AdminDashboard from './pages/Admin/AdminDashboard';
import AdminComplainList from './pages/Admin/AdminComplainList';
import AdminComplaintDetails from './pages/Admin/AdminComplaintDetails';
import UsersList from "./pages/Admin/UsersList";
import Nav from './pages/Admin/Nav';

import AccountComplaint from './pages/AccountHead/AccountComplaint';
import AccountComplaintStructure from './pages/AccountHead/AccountComplaintStructure';
import AccountHome from './pages/AccountHead/AccountHome';

import SheadComplaint from './pages/SalesHead/SheadComplaint';
import SheadComplaintStructure from './pages/SalesHead/SheadComplaintStructure';
import SheadHome from './pages/SalesHead/SheadHome';

import DispatchComplaint from './pages/DispatchHead/DispatchComplaint';
import DispatchComplaintStructure from './pages/DispatchHead/DispatchComplaintStructure';
import DispatchHome from './pages/DispatchHead/DispatchHome';

import TsmComplaint from './pages/tsm/TsmComplaint';
import TsmComplaintStructure from './pages/tsm/TsmComplaintStructure';
import TsmHome from './pages/tsm/TsmHome';

import AsmComplaint from './pages/asm/AsmComplaint';
import AsmComplaintStructure from './pages/asm/AsmComplaintStructure';
import AsmHome from './pages/asm/AsmHome';

import ProductionComplaintStructure from './pages/ProductionHead/ProductionComplaintStructure';
import ProductionComplaint from './pages/ProductionHead/ProductionComplaint';
import ProductionHome from './pages/ProductionHead/ProductionHome';

import RsmComplaint from './pages/RSM/RsmComplaint';
import RsmComplaintStructure from './pages/RSM/RsmComplaintStructure';
import RsmHome from './pages/RSM/RsmHome';
import RsmRegisterComplaint from './pages/RSM/RsmRegisterComplaint';

import ProtectedRoute from './component/Protected/ProtectedRoute';
import ResetPassword from './pages/account/ResetPassword';

function App() {
  const router = createBrowserRouter(
    createRoutesFromElements(
      <Route>
        {/* Public Route */}

        <Route path="/" element={<UserLogin />} />
        <Route path="/change" element={<ChangePassword />} />
        <Route path="/forgot" element={<ForgotPassword />} />
        <Route path='password-reset/:token' element={<ResetPassword />}></Route>

        {/* Protected Routes */}
        <Route path="/account" element={<ProtectedRoute element={<AccountHome />} allowedRoles={['2']} />} />
        <Route path="/account-complaint" element={<ProtectedRoute element={<AccountComplaint />} allowedRoles={['2']} />} />
        <Route path="/account-complaint-details/:id" element={<ProtectedRoute element={<AccountComplaintStructure />} allowedRoles={['2']} />} />

        <Route path="/production" element={<ProtectedRoute element={<ProductionHome />} allowedRoles={['3']} />} />
        <Route path="/production-complaint" element={<ProtectedRoute element={<ProductionComplaint />} allowedRoles={['3']} />} />
        <Route path="/production-complaint-details/:id" element={<ProtectedRoute element={<ProductionComplaintStructure />} allowedRoles={['3']} />} />

        <Route path="/shead" element={<ProtectedRoute element={<SheadHome />} allowedRoles={['4']} />} />
        <Route path="/shead-complaint" element={<ProtectedRoute element={<SheadComplaint />} allowedRoles={['4']} />} />
        <Route path="/shead-complaint-details/:id" element={<ProtectedRoute element={<SheadComplaintStructure />} allowedRoles={['4']} />} />

        <Route path="/dispatch" element={<ProtectedRoute element={<DispatchHome />} allowedRoles={['5']} />} />
        <Route path="/dispatch-complaint" element={<ProtectedRoute element={<DispatchComplaint />} allowedRoles={['5']} />} />
        <Route path="/dispatch-complaint-details/:id" element={<ProtectedRoute element={<DispatchComplaintStructure />} allowedRoles={['5']} />} />

        <Route path="/tsm" element={<ProtectedRoute element={<TsmHome />} allowedRoles={['8']} />} />
        <Route path="/tsm-complaint" element={<ProtectedRoute element={<TsmComplaint />} allowedRoles={['8']} />} />
        <Route path="/tsm-complaint-details/:id" element={<ProtectedRoute element={<TsmComplaintStructure />} allowedRoles={['8']} />} />

        <Route path="/asm" element={<ProtectedRoute element={<AsmHome />} allowedRoles={['9']} />} />
        <Route path="/asm-complaint" element={<ProtectedRoute element={<AsmComplaint />} allowedRoles={['9']} />} />
        <Route path="/asm-complaint-details/:id" element={<ProtectedRoute element={<AsmComplaintStructure />} allowedRoles={['9']} />} />


        <Route path="/rsm" element={<ProtectedRoute element={<RsmHome />} allowedRoles={['6']} />} />
        <Route path="/rsm-complaint" element={<ProtectedRoute element={<RsmComplaint />} allowedRoles={['6']} />} />
        <Route path="/rsm-register-complain" element={<ProtectedRoute element={<RsmRegisterComplaint />} allowedRoles={['6']} />} />
        <Route path="/rsm-complaint-details/:id" element={<ProtectedRoute element={<RsmComplaintStructure />} allowedRoles={['6']} />} />

        <Route path="/sales" element={<ProtectedRoute element={<Home />} allowedRoles={['7']} />} />
        <Route path="/complaint" element={<ProtectedRoute element={<Complaint />} allowedRoles={['7']} />} />
        <Route path="/register-complain" element={<ProtectedRoute element={<RegisterComplaint />} allowedRoles={['7']} />} />
        <Route path="sales/complaint/:id" element={<ProtectedRoute element={<ComplaintStructure />} allowedRoles={['7']} />} />

        <Route path="/admin-user-register" element={<ProtectedRoute element={<UserRegister />} allowedRoles={['1']} />} />
        <Route path="/admin" element={<ProtectedRoute element={<AdminDashboard />} allowedRoles={['1']} />} />
        <Route path="/admin-complaint-list" element={<ProtectedRoute element={<AdminComplainList />} allowedRoles={['1']} />} />
        <Route path="/admin/complaint/:id" element={<ProtectedRoute element={<AdminComplaintDetails />} allowedRoles={['1']} />} />
        <Route path="/admin-users" element={<ProtectedRoute element={<UsersList />} allowedRoles={['1']} />} />
        <Route path="/admin-nav" element={<ProtectedRoute element={<Nav />} allowedRoles={['1']} />} />
      </Route>
    )
  );

  return <RouterProvider router={router} />;
}

export default App;
